import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import image1 from './bg-goscholar.png'
import image2 from './bg-goscholar1.jpeg'
import image3 from './bg-goscholar2.jpeg'
import image4 from './bg-goscholar3.jpeg'


export default function SliderPage() {
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img className="image" src={image1} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="image" src={image2} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="image" src={image3} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="image" src={image4} alt="" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
