import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import logo from "../goscholar-logo.png";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [show, setShow] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const handleShow = () => setShow(!show);
  const dropDownToggle = () => {
    setDropDown(!dropDown);
  };
  const closeMenu = () => {
    setIsOpen(false);
    setShow(false);
    setDropDown(false);
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="header">
      <div className="fixed-header">
        <div className="header-content">
          <div className="logo">
            <Link to="/" onClick={scrollToTop}>
              <img src={logo} alt="GoScholar" className="goscholar--logo" />
            </Link>
            {isOpen ? (
              <FaTimes onClick={toggle} className="menu" />
            ) : (
              <FaBars onClick={toggle} className="menu" />
            )}
          </div>
          <ul className={isOpen ? "nav-links open" : "nav-links"}>
            <Link to="/" className="link" onClick={() => { closeMenu(); scrollToTop(); }}>
              <li>Home</li>
            </Link>
            <div className="dropDown">
              <li onClick={dropDownToggle}>
                Services <FaChevronDown />
              </li>
              {dropDown && (
                <ul className="dropDown-menu">
                  <li>
                    <Link
                      to="/academic-writing"
                      onClick={() => { closeMenu(); scrollToTop(); }}
                      className="services--link"
                    >
                      Academic writing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/voice-over"
                      onClick={() => { closeMenu(); scrollToTop(); }}
                      className="services--link"
                    >
                      Voice Over
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Branding&Design"
                      onClick={() => { closeMenu(); scrollToTop(); }}
                      className="services--link"
                    >
                      Branding & Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/copywriting"
                      onClick={() => { closeMenu(); scrollToTop(); }}
                      className="services--link"
                    >
                      Copy Writing & Content Writing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/proofreading"
                      onClick={() => { closeMenu(); scrollToTop(); }}
                      className="services--link"
                    >
                      Proofreading
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/advert&promotions"
                      onClick={() => { closeMenu(); scrollToTop(); }}
                      className="services--link"
                    >
                      Advert and promotions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/social-media"
                      onClick={() => { closeMenu(); scrollToTop(); }}
                      className="services--link"
                    >
                      Social Media management
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <Link to="/about" className="link" onClick={() => { closeMenu(); scrollToTop(); }}>
              <li>About Us</li>
            </Link>
           
            <Link to="/blog" className="link" onClick={() => { closeMenu(); scrollToTop(); }}>
              <li>Blog</li>
            </Link>
            <Link to="/contact" className="link" onClick={() => { closeMenu(); scrollToTop(); }}>
              <li>Contact</li>
            </Link>
            <Link to="/partner" className="link" onClick={() => { closeMenu(); scrollToTop(); }}>
              <li>Join our partner</li>
            </Link>
            <div className="getStarted--dropdown">
              <button onClick={handleShow} className="getStarted--btn">
                Get Started <FaChevronDown />
              </button>
              {show && (
                <ul className="getStarted-menu">
                  <li onClick={() => { closeMenu(); scrollToTop(); }}>
                    <Link to="/academic-form" className="services--link">
                      For Academic Services
                    </Link>
                  </li>
                  <li onClick={() => { closeMenu(); scrollToTop(); }}>
                    <Link to='/business-form' className="services--link">
                      For Business Services
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}
