const Faq = [
    {
      question: "What services do you offer?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: "How do I get started with your services? ",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: " What if I am not satisfied with the final product?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: "How are your services priced?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: " Can I track the progress of my project?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: "What business consultancy services do you offer?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: "What types of academic writing do you assist with?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: "Are your services confidential?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
    {
      question: " How can I contact customer support?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia impedit similique consectetur?",
    },
  ];

  export default Faq;