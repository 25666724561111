import React, { useEffect } from "react";
import Banner from "../banner/Banner";
import banner from "../../../src/copywrite-banner.png";
import "./Copywrite.css";
import avatar from "../../../src/avatar.png";
import icon1 from "../../../src/icons/whychoose-icon1.png";
import icon2 from "../../../src/icons/whychoose-icon2.png";
import icon3 from "../../../src/icons/whychoose-icon3.png";
import icon4 from "../../../src/icons/whychoose-icon4.png";
import Footer from "../../pages/Footer";
import Contact from "../../../src/components/contact/Contact";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Copywrite() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <Banner image={banner} />
      <div className="copywrite-container">
        <p className="intro--header"> Copywriting & Content Marketing</p>
        <div className="copywrite-content">
          <p>
            Elevate your brand and captivate your audience with expert
            copywriting and content marketing from Go Scholar. Our team of
            skilled writers specializes in crafting compelling messages and
            strategic content that resonates with your target audience, driving
            engagement and achieving your marketing goals
          </p>
          <p className="what-we-offer intro--header">What We Offer</p>
          <div className="what-we-offer">
            <div className="what-we-offer-grid">
              <div
                className="what-we-offer-left"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <div className="what-we-offer-left-card">
                  <h5>SEO Content Writing</h5>
                  <p>
                    Improve your search engine ranking and attract organic
                    traffic with strategic SEO content writing. We'll weave
                    relevant keywords seamlessly into informative and engaging
                    content that Google loves.
                  </p>
                </div>

                <div className="what-we-offer-left-card">
                  <h5>Social Media Content</h5>
                  <p>
                    Fuel your social media presence with captivating content
                    that sparks conversation and increases engagement. We'll
                    create posts that are tailored to each platform and resonate
                    with your audience.
                  </p>
                </div>

                <div className="what-we-offer-left-card">
                  <h5>Blog Writing</h5>
                  <p>
                    Establish yourself as a thought leader in your industry with
                    informative and engaging blog posts. Our writers will create
                    content that attracts new leads, builds trust, and positions
                    you as an expert.
                  </p>
                </div>
              </div>
              <div
                className="what-we-offer-center"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <img src={avatar} alt="" />
              </div>
              <div
                className="what-we-offer-right"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <div className="what-we-offer-right-card">
                  <h5>Email Marketing Copy</h5>
                  <p>
                    Craft email campaigns that nurture leads, convert sales, and
                    build lasting customer relationships. Our persuasive email
                    copywriting will drive results for your marketing efforts.
                  </p>
                </div>

                <div className="what-we-offer-right-card">
                  <h5>Website Copywriting</h5>
                  <p>
                    Transform your website into a lead-generating machine with
                    engaging and informative website copy. From clear navigation
                    to persuasive product descriptions, we'll craft content that
                    keeps visitors engaged and drives conversions.
                  </p>
                </div>

                <div className="what-we-offer-right-card">
                  <h5>Business Proposals</h5>
                  <p>
                    Don't just write a proposal, tell your story. Our expert
                    writers will craft persuasive business proposals that secure
                    the partnerships and investments you deserve. Our proposals
                    are designed to highlight your strengths, address potential
                    concerns, and position your business as the best choice.
                  </p>
                </div>
              </div>
            </div>

            <div className="why-choose-copywrite">
              <p className="intro--header">
                Why Choose Go Scholar Copywriting & Content Marketing?
              </p>
              <div className="copywrite-why-choose-us" data-aos="fade-up"
                  data-aos-duration="1500">
                <div className="why-choose-us-top">
                  <div className="why-choose-us-top-card">
                    <img src={icon1} alt="" />
                    <p>
                      Results-Oriented: Our focus is on crafting content that
                      achieves your specific marketing goals.
                    </p>
                  </div>

                  <div className="why-choose-us-top-card">
                    <img src={icon2} alt="" />
                    <p>
                      Expert Writers: Our team is comprised of skilled writers
                      with experience across various industries.
                    </p>
                  </div>

                  <div className="why-choose-us-top-card">
                    <img src={icon3} alt="" />
                    <p>
                      Exceptional Client Service: We prioritize clear
                      communication and collaboration throughout the entire
                      process.
                    </p>
                  </div>
                </div>
                <div className="why-choose-us-bottom">
                  <div className="why-choose-us-bottom-card">
                    <img src={icon4} alt="" />
                    <p>
                      Data-Driven Approach: We combine creativity with data
                      analysis to ensure your content resonates with your target
                      audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
