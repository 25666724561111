import React from "react";
import Banner from "../banner/Banner";
import banner from "../../../src/social-banner.png";
import Info from "../info/Info";
import socialInfo from "../../../src/social-info.png";
import Footer from "../../pages/Footer";
import Contact from "../contact/Contact";

export default function SocialMedia() {
  return (
    <div>
      <div className="social-media-container">
        <Banner image={banner} />
        <Info
        header='Social media management'
          text1="Maintaining a robust social media presence is key to engaging with your audience and growing your brand."
          text2="Go Scholar’s social media management services cover everything from content creation and curation to audience engagement and performance analysis. We develop and implement social media strategies tailored to your goals, whether it’s increasing brand awareness, driving traffic, or boosting sales. Let us handle your social media while you focus on running your business."
          image={socialInfo}
        />
        <Contact/>
      </div>
      <Footer/>
    </div>
  );
}
