import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { FaLocationDot, FaX } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Footer() {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <footer className="footer">
        <div>
          <div className="footer--content">
            {/* Vita Links */}
            <div className="vital--links">
              <h2>VITAL LINKS</h2>
              <ul>
                <li>
                  <Link className="social-link" to="/about" onClick={scrollToTop}>
                    Meet us
                  </Link>
                </li>
                <li>
                  <Link className="social-link" to="/blog" onClick={scrollToTop}>
                    Latest News
                  </Link>
                </li>
                <li>
                  <Link className="social-link" to="/contact" onClick={scrollToTop}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <br />
            {/* Our Services Links */}
            <div className="our--services">
              <h2>OUR SERVICES</h2>
              <div className="our-services-links">
                <ul>
                  <li>
                    <Link className="social-link" to="/academic-writing" onClick={scrollToTop}>
                      Academic Writing
                    </Link>
                  </li>
                  <li>
                    <Link className="social-link" to="/copywriting" onClick={scrollToTop}>
                      Copywriting & Content writing
                    </Link>
                  </li>
                  <li>
                    <Link className="social-link" to="/proofreading" onClick={scrollToTop}>
                      Proofreading
                    </Link>
                  </li>
                  <li>
                    <Link className="social-link" to="/advert&promotions" onClick={scrollToTop}>
                      Advert and promotions
                    </Link>
                  </li>
                </ul>
                <ul className="links-right">
                  <li>
                    <Link className="social-link" to="/voice-over" onClick={scrollToTop}>
                      Voice Over
                    </Link>
                  </li>
                  <li>
                    <Link className="social-link" to="/social-media" onClick={scrollToTop}>
                      Social Media Management
                    </Link>
                  </li>
                  <li>
                    <Link className="social-link" to="/Branding&Design" onClick={scrollToTop}>
                      Branding & Design
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-newsletter">
              <p>Stay ahead in business and learning!</p>
              <div className="footer-newsletter-input">
                <input type="text" placeholder="email address" />
                <button>
                  <FaChevronRight className="newsletter-icon" />
                </button>
              </div>
              <p>
                Subscribe to be our newsletter for top business and educational
                tips.
              </p>
            </div>
          </div>
          <hr />
          <div className="information">
            <div className="info-number information-info">
              <div className="info-social-icon">
                <FaPhoneAlt className="information-icon" />
              </div>
              <p>+23409159523278</p>
            </div>
            <div className="info-email information-info">
              <div className="info-social-icon">
                <FaEnvelope className="information-icon" />
              </div>
              <p>Hello@goscholar.com.ng</p>
            </div>
            <div className="info-location information-info">
              <div className="info-social-icon">
                <FaLocationDot className="information-icon" />
              </div>
              <p>Lagos, Nigeria</p>
            </div>
          </div>
          <hr />
          <div className="social">
            <ul>
              <p>Follow us on</p>
              <li>
                <Link to="/" className="social-link" onClick={scrollToTop}>
                  <FaFacebook />
                </Link>
              </li>
              <li>
                <Link to="/" className="social-link" onClick={scrollToTop}>
                  <FaX />
                </Link>
              </li>
              <li>
                <Link to="/" className="social-link" onClick={scrollToTop}>
                  <FaYoutube />
                </Link>
              </li>
              <li>
                <Link to="/" className="social-link" onClick={scrollToTop}>
                  <FaLinkedin />
                </Link>
              </li>
            </ul>
            <p>
              © 2024 Goscholar | The leading education support network. All
              rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
