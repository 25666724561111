import React, { useState } from "react";
import Modal from "../../../src/pages/Modal"; // Adjust the import path as necessary
import "./Form.css";

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    businessSection: "",
    projectSummary: "",
    startDate: "",
    dueDate: "",
    location: "",
    additionalInfo: "",
    currency: "",
    other: "",
    budget: "",
    find: "",
  });
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.businessSection)
      newErrors.businessSection = "Business section is required";
    if (!formData.startDate)
      newErrors.startDate = "Proposed start date is required";
    if (!formData.dueDate) newErrors.dueDate = "Delivery due date is required";
    if (!formData.location) newErrors.location = "Location is required";
    if (!formData.currency) newErrors.currency = "Currency is required";
    if (!formData.find) newErrors.find = "Please select an option";

    setErrors(newErrors);
    setTimeout(() => {
      setErrors({});
    }, 3000);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsModalOpen(true); // Open the modal if validation passes
      setFormData({
        name: "",
        email: "",
        phone: "",
        businessSection: "",
        projectSummary: "",
        startDate: "",
        dueDate: "",
        location: "",
        additionalInfo: "",
        currency: "",
        other: "",
        budget: "",
        find: "",
      });
      setErrors({});
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="business-form">
      <div className="formComponent all--pages">
        <p>
          Required <span>*</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              <b>1. Name</b> <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
            />
            {errors.name && <span>{errors.name}</span>}
          </div>

          <div>
            <label>
              <b>2. Email</b> <span>*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
            />
            {errors.email && <span>{errors.email}</span>}
          </div>

          <div>
            <label>
              <b>3. Phone</b> <span>*</span>
            </label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your answer"
            />
            {errors.phone && <span>{errors.phone}</span>}
          </div>

          <div>
            <label>
              <b>4. Business Services?</b> <span>*</span>
            </label>
            <div>
              <label>
                <input
                  type="radio"
                  name="businessSection"
                  value="Option 1"
                  checked={formData.businessSection === "Option 1"}
                  onChange={handleChange}
                />
                Branding & Design
              </label>
              <label>
                <input
                  type="radio"
                  name="businessSection"
                  value="Option 2"
                  checked={formData.businessSection === "Option 2"}
                  onChange={handleChange}
                />
                Voice Over
              </label>
              <label>
                <input
                  type="radio"
                  name="businessSection"
                  value="Option 3"
                  checked={formData.businessSection === "Option 3"}
                  onChange={handleChange}
                />
                Copywriting
              </label>
              <label>
                <input
                  type="radio"
                  name="businessSection"
                  value="Option 4"
                  checked={formData.businessSection === "Option 4"}
                  onChange={handleChange}
                />
                Advertising & Promotion
              </label>
              <label>
                <input
                  type="text"
                  name="businessSection"
                  onChange={handleChange}
                  placeholder="Other"
                  onFocus={() =>
                    setFormData({ ...formData, businessSection: "" })
                  }
                />
              </label>
            </div>
            {errors.businessSection && <span>{errors.businessSection}</span>}
          </div>

          <div>
            <label>
              <b>
                5. Project brief summary?(Tell us a bit more about your project){" "}
              </b>
              <span>*</span>
            </label>
            <textarea
              name="projectSummary"
              value={formData.projectSummary}
              onChange={handleChange}
              placeholder="Enter your answer"
            />
          </div>

          <div>
            <label>
              Proposed project start date? (Note: Payment determines project
              start date) <span>*</span>
            </label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
            {errors.startDate && <span>{errors.startDate}</span>}
          </div>

          <div>
            <label>
              <b>6. Project Delivery Due Date?</b> <span>*</span>
            </label>
            <input
              type="date"
              name="dueDate"
              value={formData.dueDate}
              onChange={handleChange}
            />
            {errors.dueDate && <span>{errors.dueDate}</span>}
          </div>

          <div>
            <label>
              <b>7. Location of Business?</b> <span>*</span>
            </label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
            {errors.location && <span>{errors.location}</span>}
          </div>

          <div>
            <label>
              <b>
                8. Additional information that would be helpful to the project
                (No. of pages,referencing style, project sample and structure,
                e.t.c.)?
              </b>
              <span>*</span>
            </label>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
            />
          </div>

          <div>
            <label>
              <b>
                9. What currency are you paying in? (Charges are based on the
                currency of the country of institution) <span>*</span>
              </b>
            </label>
            <label>
              <input
                type="radio"
                name="currency"
                value="naira"
                checked={formData.currency === "naira"}
                onChange={handleChange}
              />
              Naira
            </label>
            <label>
              <input
                type="radio"
                name="currency"
                value="dollar"
                checked={formData.currency === "dollar"}
                onChange={handleChange}
              />
              Dollar
            </label>
            <label>
              <input
                type="radio"
                name="currency"
                value="pound"
                checked={formData.currency === "pound"}
                onChange={handleChange}
              />
              Pound
            </label>
            <label>
              <input
                type="radio"
                name="currency"
                value="euro"
                checked={formData.currency === "euro"}
                onChange={handleChange}
              />
              Euro
            </label>

            <label>
              <input
                type="text"
                name="currency"
                onChange={handleChange}
                placeholder="Other"
                onFocus={() => setFormData({ ...formData, currency: "" })}
              />
            </label>
            {errors.currency && <span>{errors.currency}</span>}
          </div>

          <div>
            <label>
              <b>
                10. Project Budget (How much are you willing to pay for the
                project)?
              </b>
              <span>*</span>
            </label>
            <input
              type="text"
              name="budget"
              value={formData.budget}
              onChange={handleChange}
            />
            {errors.budget && <span>{errors.budget}</span>}
          </div>

          <div>
            <label>
              <b>
                11. How did you find us? <span>*</span>
              </b>
            </label>
            <label>
              <input
                type="checkbox"
                name="find"
                value="social media"
                checked={formData.find === "social media"}
                onChange={handleChange}
              />
              social media
            </label>
            <label>
              <input
                type="checkbox"
                name="find"
                value="Testimonial"
                checked={formData.find === "Testimonial"}
                onChange={handleChange}
              />
              Testimonial
            </label>
            <label>
              <input
                type="checkbox"
                name="find"
                value="Referral"
                checked={formData.find === "Referral"}
                onChange={handleChange}
              />
              Referral
            </label>
            <label>
              <input
                type="checkbox"
                name="find"
                value="Google"
                checked={formData.find === "Google"}
                onChange={handleChange}
              />
              Google
            </label>
            <label>
              <input
                type="text"
                name="find"
                placeholder="Other"
                onChange={handleChange}
                onFocus={() => setFormData({ ...formData, find: "" })}
              />
            </label>
            {errors.find && <span>{errors.find}</span>}
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>

        {isModalOpen && <Modal closeModal={closeModal} />}
      </div>
    </div>
  );
};

export default FormComponent;
