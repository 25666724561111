import React from "react";
import Banner from "../banner/Banner";
import Info from "../info/Info";
import Contact from "../contact/Contact";
import Footer from "../../pages/Footer";
import banner from "../../../src/services-bg6.png";
import info from "../../../src/voice-info.png";

export default function Voice() {
  return (
    <div>
      <div className="advert-container">
        <div className="voice-over">
          <Banner image={banner} />
          <Info
            header="Voice Over"
            text1="Voice over services can significantly enhance the impact of your multimedia projects."
            text2="At Go Scholar, our talented voice artists deliver professional and engaging audio for a variety of uses, including videos, commercials, presentations, and more.We provide voices that suit the tone and style of your project, ensuring your message is delivered clearly and memorably. Whether you need a warm, friendly tone or a strong, authoritative voice, our team has got you covered.

 Book a professional voice over today!"
            image={info}
          />
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  );
}
