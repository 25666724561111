import React, { useEffect } from "react";
import "./Partner.css";
import Banner from "../../components/banner/Banner";
import banner from "../../../src/partner-banner.png";
import Footer from "../Footer";
import partner1 from "../../../src/icons/partner-1.png";
import partner2 from "../../../src/icons/partner-2.png";
import partner3 from "../../../src/icons/partner-3.png";
import partner4 from "../../../src/icons/partner-4.png";
import flex1 from "../../../src/flex-card-1.png";
import flex2 from "../../../src/flex-card-2.png";
import flex3 from "../../../src/flex-card-3.png";
import flex4 from "../../../src/flex-card-4.png";
import flex5 from "../../../src/flex-card-5.png";
import flex6 from "../../../src/flex-card-6.png";
import Contact from "../../components/contact/Contact";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Partner() {

  useEffect(() => {
    Aos.init();
  }, []);
  
  return (
    <div>
      <Banner
        image={banner}
        text1="Join Our Partner Network"
        text2="Expand Your Reach and Expertise with Go Scholar"
      />
      <div className="partner-container">
        <div className="partner-content">
          <p className="partner-header">
            Go Scholar is an all-in-one academic and professional services
            platform, providing top-tier support to students, researchers, and
            businesses. We’re seeking talented and passionate individuals to
            join our growing partner network!
          </p>
          <h5 className="intro--header why-partner">
            Why Partner with Go Scholar?
          </h5>
          <div className="partner-info">
            <div className="partner-info-card">
              <img src={partner1} alt="" />
              <div className="partner-card-text">
                <h5>Expand Your Reach</h5>
                <p>
                  Gain access to a vast network of clients seeking academic
                  writing, proofreading, business development, marketing, and
                  creative services.
                </p>
              </div>
            </div>

            <div className="partner-info-card">
              <img src={partner2} alt="" />
              <div className="partner-card-text">
                <h5>Competitive Rates</h5>
                <p>Earn competitive compensation for your expertise.</p>
              </div>
            </div>
            <div className="partner-info-card">
              <img src={partner3} alt="" />
              <div className="partner-card-text">
                <h5>Flexible Work Opportunities</h5>
                <p>
                  Set your own hours and work on projects that align with your
                  skills and interests.
                </p>
              </div>
            </div>
            <div className="partner-info-card">
              <img src={partner4} alt="" />
              <div className="partner-card-text">
                <h5>Professional Development</h5>
                <p>
                  Benefit from ongoing learning resources and opportunities to
                  collaborate with a network of talented professionals.
                </p>
              </div>
            </div>
          </div>

          <div className="partner-looking-for">
            <h5 className="intro--header">Who We’re Looking For</h5>
            <div className="looking-for-flex">
              <div className="flex-card">
                <div className="flex-card-text" data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Academic Writers</h5>
                  <p>
                    Skilled in research, writing, and editing across various
                    academic disciplines.
                  </p>
                </div>
                <img src={flex1} alt="" data-aos="fade-left"
                data-aos-duration="1500"/>
              </div>
              <div className="flex-card flex-2">
                <div className="flex-card-text" data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Proofreaders</h5>
                  <p>
                    Possess a keen eye for detail and a strong understanding of
                    grammar, punctuation, and style.
                  </p>
                </div>
                <img src={flex2} alt="" data-aos="fade-left"
                data-aos-duration="1500"/>
              </div>

              <div className="flex-card flex-3">
                <div className="flex-card-text" data-aos="fade-right"
                data-aos-duration="1500">
                  <h5> Copywriters & Content Marketers</h5>
                  <p>
                    Experts in crafting compelling and persuasive copies for
                    businesses of all sizes.
                  </p>
                </div>
                <img src={flex1} alt="" data-aos="fade-left"
                data-aos-duration="1500" />
              </div>

              <div className="flex-card flex-4">
                <div className="flex-card-text" data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Advertising & Promotions Specialists</h5>
                  <p>
                    Experienced in developing and executing marketing campaigns
                    across various channels
                  </p>
                </div>
                <img src={flex3} alt="" data-aos="fade-left"
                data-aos-duration="1500" />
              </div>

              <div className="flex-card flex-5">
                <div className="flex-card-text" data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Social Media Managers</h5>
                  <p>
                    Savvy social media strategists with a proven track record of
                    audience engagement and community growth.
                  </p>
                </div>
                <img src={flex4} alt="" data-aos="fade-left"
                data-aos-duration="1500" />
              </div>

              <div className="flex-card flex-6">
                <div className="flex-card-text" data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Voice Over Artists</h5>
                  <p>
                    Professional voice actors with clear and engaging delivery.
                  </p>
                </div>
                <img src={flex5} alt="" data-aos="fade-left"
                data-aos-duration="1500" />
              </div>

              <div className="flex-card flex-7">
                <div className="flex-card-text" data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Branding & Graphic Designers</h5>
                  <p>
                    Adept at creating visually appealing and impactful brand
                    identities and marketing materials.
                  </p>
                </div>
                <img src={flex6} alt="" data-aos="fade-left"
                data-aos-duration="1500" />
              </div>
            </div>
            <div className="ready-to-join">
              <h5 className="intro--header">Ready to Join?</h5>
              <p>
                We invite passionate individuals with a commitment to quality
                and client satisfaction to apply. The application process is
                quick and easy! Simply submit your portfolio and a brief
                statement outlining your relevant skills and experience. Become
                a Go Scholar Partner Today!
              </p>
            </div>
          </div>
        </div>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
