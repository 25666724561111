import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import {
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaLightbulb,
} from "react-icons/fa";
import SliderPage from "../SliderPage";
import Image from "../why-choose.png";
import ContactImg from "../goscholar-contact.png";
import Footer from "./Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import { FaShareNodes } from "react-icons/fa6";
import voiceIcon from "../../src/icons/voice-icon.png";
import academicIcon from "../../src/icons/academic-icon.png";
import advertIcon from "../../src/icons/advert-icon.png";
import brandIcon from "../../src/icons/branding-icon.png";
import copyIcon from "../../src/icons/copy-icon.png";
import proofIcon from "../../src/icons/proof-icon.png";
import socialIcon from "../../src/icons/social-icon.png";
import { FaChevronDown } from "react-icons/fa6";
import Faq from "../FaqData";
export default function HomePage() {
  useEffect(() => {
    Aos.init();
  }, []);

  //Blog

  const [posts, setPosts] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  const [openIndex, setOpenIndex] = useState(null);
  const showIndex = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const closeMenu = () => setShow(false);


  useEffect(() => {
    client
      .fetch(
        `
        *[_type == "post"]{
          title,
            slug,
                  body,
                  mainImage {
                    asset -> {
                      _id,
                      url
                    },
                    alt
            }
        }
        `
      )
      .then((data) => {
        setPosts(data);
      })
      .catch(console.error);
  }, []);

  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      localStorage.setItem("Form data:", JSON.stringify(contactForm));
      setSuccessMessage("Message sent successfully!");
      setContactForm({
        name: "",
        email: "",
        message: "",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } else {
      setErrors(newErrors);
      setTimeout(() => {
        setErrors({});
      }, 3000);
    }
  };

  const validateForm = () => {
    const { name, email, message } = contactForm;
    const newErrors = {};

    if (!name) newErrors.name = "Name is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }
    if (!message) newErrors.message = "Message is required";

    return newErrors;
  };

  const handleShare = (post) => {
    if (navigator.share) {
      navigator
        .share({
          title: post.title,
          url: `${window.location.origin}/blog/${post.slug.current}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      alert(
        "Sharing is not supported in your browser. Copy the link manually: " +
          `${window.location.origin}/blog/${post.slug.current}`
      );
    }
  };

  return (
    <div className="landingpage">
      <section className="landingpage-content">
        <div className="background">
          <SliderPage />
        </div>
        <div className="background-overlay">
          <div className="overlay-content">
            <h1 data-aos="fade-up" data-aos-duration="1000">
              Your Gateway to Academic Excellence and Business Triumph.
            </h1>
            <p data-aos="fade-up" data-aos-duration="1500">
              Empower your success with top-notch academic writing, persuasive
              proposals, <span>captivating ads</span>, professional voice overs,
              and standout branding websites.
            </p>
            <div className="getStarted--dropdown">
              <button onClick={handleShow} className="getStarted--btn">
                Get Started <FaChevronDown />
              </button>
              {show && (
                <ul className="getStarted-menu getStarted-home">
                  <li onClick={closeMenu}>
                    <Link to="/academic-form" className="services--link">
                      For Academic services
                    </Link>
                  </li>
                  <li onClick={closeMenu}>
                    <Link to='/business-form' className="services--link">
                      For Business services
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="services-section">
        <h1 className="intro--header">Our Services</h1>
        <div className="services-section-content">
          <div className="services-card">
            <div className="services-card--icon">
              <img src={academicIcon} alt="icon" />
            </div>
            <h5>Academic writing</h5>
            <p>
              Unlock your academic potential, with our expert writing services{" "}
            </p>
            <Link to="/academic-writing" className="service-card-link">
              <p>
                learn more <FaChevronRight />
              </p>
            </Link>
          </div>

          <div className="services-card">
            <div className="services-card--icon">
              <img src={advertIcon} alt="icon" />
            </div>
            <h5>Advert & promotions</h5>
            <p>
              Boost your brand visibility with strategic advertisements that
              resonate
            </p>
            <Link to="/advert&promotions" className="service-card-link">
              <p>
                learn more <FaChevronRight />
              </p>
            </Link>
          </div>

          <div className="services-card">
            <div className="services-card--icon">
              <img src={brandIcon} alt="icon" />
            </div>
            <h5>Branding & Design</h5>
            <p>
              Unlock your academic potential, with our expert writing services
            </p>
            <Link to="/Branding&Design" className="service-card-link">
              <p>
                learn more <FaChevronRight />
              </p>
            </Link>
          </div>

          <div className="services-card">
            <div className="services-card--icon">
              <img src={copyIcon} alt="icon" />
            </div>
            <h5>Copy writing & Content Marketing</h5>
            <p>
              Your success starts here with our persuasive writing that hook
              readers
            </p>
            <Link to = '/copywriting' className="service-card-link">
              <p>
                learn more <FaChevronRight />
              </p>
            </Link>
          </div>

          <div className="services-card">
            <div className="services-card--icon">
              <img src={proofIcon} alt="icon" />
            </div>
            <h5>Proofreading</h5>
            <p>Enhance your message, with our expert proofreading services</p>
            <Link to="/proofreading" className="service-card-link">
              <p>
                learn more <FaChevronRight />
              </p>
            </Link>
          </div>

          <div className="services-card">
            <div className="services-card--icon">
              <img src={socialIcon} alt="icon" />
            </div>
            <h5>Social media Management</h5>
            <p>
              Increase your social media presence wth our strategies while you
              focus on running your business
            </p>
            <Link to="/social-media" className="service-card-link">
              <p>
                learn more <FaChevronRight />
              </p>
            </Link>
          </div>

          <div className="services-card">
            <div className="services-card--icon">
              <img src={voiceIcon} alt="icon" />
            </div>
            <h5>Voice Over</h5>
            <p>
              Unlock your academic potential, with our expert writing services
            </p>
            <Link to="/voice-over" className="service-card-link">
              <p>
                learn more <FaChevronRight />
              </p>
            </Link>
          </div>
        </div>
      </div>

      <section className="rating--content">
        <div className="rating">
          <div className="business">
            <h4>50+</h4>
            <p>Businesses</p>
          </div>
          <div className="education">
            <h4>10+</h4>
            <p>Educational Writings</p>
          </div>
          <div className="experience">
            <h4>2+</h4>
            <p>Years of Experience</p>
          </div>
        </div>
      </section>
      <div className="why-choose-us">
        <h2 className="intro--header">Why Choose Us</h2>
        <div className="why-choose-content">
          <div className="why-choose--info">
            <div className="creativity">
              <div className="info info1">
                <span className="bulb-icon">
                  <FaLightbulb className="info-icon" />
                </span>
                <span>Creativity</span>
              </div>
              <div className="info info2">
                <span className="bulb-icon">
                  <FaLightbulb className="info-icon" />
                </span>
                <span>Affordability</span>
              </div>
            </div>
            <div className="prof">
              <div className="info info3">
                <span className="bulb-icon">
                  <FaLightbulb className="info-icon" />
                </span>
                <span>Timeliness</span>
              </div>
              <div className="info info4">
                <span className="bulb-icon">
                  <FaLightbulb className="info-icon" />
                </span>
                <span>Professionalism</span>
              </div>
            </div>
          </div>
          <div className="why-choose-img">
            <img src={Image} className="why-choose-img" />
          </div>
        </div>
      </div>

      <br />

      <div className="client-feedback">
        <h2 className="intro--header">Our Clients Feedback</h2>
        <div className="client-feedback-cards">
          <div
            className="feedback-card"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="feedback-header">
              <img src={Image} className="feedback-img" />
              <div className="feedback-card-header">
                <h4>Jacob Watson</h4>
                <p>Content Creator</p>
              </div>
            </div>
            <p>
              “This was a game-changer for my podcast. The artist they provided
              had a captivating tone and impeccable delivery, making my episodes
              sound polished and professional. Highly recommend”
            </p>
          </div>
          <div
            className="feedback-card"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div className="feedback-header">
              <img src={Image} className="feedback-img" />
              <div className="feedback-card-header">
                <h4>Anne Marie</h4>
                <p>Student</p>
              </div>
            </div>
            <p>
              "Thanks to the academic writing service, I was able to submit a
              top-quality research paper on time and with confidence. I highly
              recommend their service to anyone looking for professional
              assistance in their academic endeavors.
            </p>
          </div>
          <div
            className="feedback-card"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="feedback-header">
              <img src={Image} className="feedback-img" />
              <div className="feedback-card-header">
                <h4>Jacob Watson</h4>
                <p>Manager</p>
              </div>
            </div>
            <p>
              “ I secured a game-changing partnership that propelled my business
              to new heights. Their expertise and attention to detail
              transformed my vision into a compelling proposal that captivated
              investors and stakeholders. I couldn't be happier with the
              results”
            </p>
          </div>
        </div>
      </div>

      <div className="conversation">
        <h2 className="intro--header">Start a conversation</h2>
        <div className="contact2">
          <div className="contact-left">
            <h4>Do you have questions or are you ready to start a project?</h4>
            <p>
              Have questions or ready to start a project? contact us today to
              see how we can assist you. we are readily available to move your
              academics or businesses forward
            </p>
          </div>
          <div className="contact2-form">
            <h2>Book us now</h2>
            <form onSubmit={handleSubmit} className="contact2-form-content">
              <label htmlFor="name">Name</label>
              <br />
              <input
                type="text"
                name="name"
                value={contactForm.name}
                placeholder="Enter a name"
                onChange={handleChange}
                className="contact2-input"
              />
              {errors.name && <span className="error-msg">{errors.name}</span>}
              <br />
              <label htmlFor="email">Email</label>
              <br />
              <input
                type="text"
                name="email"
                value={contactForm.email}
                placeholder="Enter your email"
                onChange={handleChange}
                className="contact2-input"
              />
              {errors.email && (
                <span className="error-msg">{errors.email}</span>
              )}
              <br />
              <br />
              <select onChange={handleChange} className="services-dropdown">
                <option value="">Select a Service</option>
                <option value="academic-writing">Academic Writing</option>
                <option value="voice-over">Voice Over</option>
                <option value="editing">SOP & Research proposals</option>
                <option value="proofreading">Proofreading</option>
                <option value="translation">Business proposal writing</option>
                <option value="transcription">Advert and promotions</option>
                <option value="copywriting">Advert and promotions</option>
                <option value="content-creation">Thesis</option>
              </select>
              <br />
              <br />
              <label htmlFor="message">Message</label>
              <br />
              <textarea
                type="text"
                name="message"
                value={contactForm.message}
                onChange={handleChange}
                className="contact2-input"
              />
              {errors.message && (
                <span className="error-msg">{errors.message}</span>
              )}
              <br />
              <button type="submit" className="button contact2-btn">
                Send
              </button>
              {successMessage && (
                <span className="success-msg">{successMessage}</span>
              )}
            </form>
          </div>
        </div>
      </div>

      <section className="blog">
        <div className="blog--content">
          <h2 className="intro--header">Our Latest News Feed</h2>
          <div className="blog--section">
            <div className="blog--cards">
              {posts &&
                posts.slice(0, 3).map((post) => (
                  <article key={post._id}>
                    <img src={post.mainImage.asset.url} alt={post.title} />
                   <div className="article-details">
                   <h4>{post.title}</h4>
                    <div className="blog-link">
                      <Link
                        className="blog--links"
                        to={`/blog/${post.slug.current}`}
                      >
                        Read more
                      </Link>
                    </div>
                   </div>
                    {/* <p onClick={() => handleShare(post)} className="share--btn">
                      <FaShareNodes /> Share
                    </p> */}
                  </article>
                ))}
            </div>
            <div className="show-more-blog">
              <Link className="show-more-link" to="/blog">
                See all
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-container">
        <div className="faq--section">
          <h2 className="intro--header">FAQ</h2>
          <p>Frequently Asked Questions</p>
          <div className="faq--content">
            {Faq.map((faq, index) => (
              <div className="faq" key={index}>
                <div className="faq-question" onClick={() => showIndex(index)}>
                  <p>{faq.question}</p>
                  {openIndex === index ? <div className="faq--icon"><FaChevronDown  /></div>  : <div className="faq--icon"><FaChevronRight /></div>}
                </div>
                {openIndex === index && (
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
