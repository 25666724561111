import React from "react";
import Banner from "../banner/Banner";
import Info from "../info/Info";
import Contact from "../contact/Contact";
import Footer from "../../pages/Footer";
import advertBanner from "../../../src/services-bg1.png";
import advertInfo from "../../../src/advert-info.png";

export default function Advert() {
  return (
    <div>
      <div className="advert-container">
        <div className="Advert and Promotions">
          <Banner image={advertBanner} />
          <Info
            header="Adverts and Promotions"
            text1="Effective advertising and promotional strategies are crucial for capturing your audience's attention and driving engagement"
            text2="Go Scholar designs and implements creative and strategic advertising campaigns that enhance your brand’s visibility across various platforms. From crafting compelling copy to selecting the right media channels, we ensure your message reaches and resonates with your target audience.

Start your promotional campaign with us!"
            image={advertInfo}
          />
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  );
}
