import React , {useState}from 'react'
import './Contact.css'
import { FaPhoneAlt, FaEnvelope, FaSearchLocation } from "react-icons/fa";

export default function Contact(props) {
    const [contactForm, setContactForm] = useState({
        name: "",
        phone: "",
        email: "",
        service: "",
        message: "",
      });
    
      const [errors, setErrors] = useState({});
      const [successMessage, setSuccessMessage] = useState("");
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setContactForm((prevForm) => ({
          ...prevForm,
          [name]: value,
        }));
      };
    
      const validateForm = () => {
        const newErrors = {};
        if (!contactForm.name) newErrors.name = "Name is required";
        if (!contactForm.email) newErrors.email = "Email is required";
        if (!contactForm.message) newErrors.message = "Message is required";
        if (!contactForm.service) newErrors.service = "Please select a service";
        return newErrors;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length === 0) {
          localStorage.setItem("Form data:", JSON.stringify(contactForm));
          setSuccessMessage("Message sent successfully!");
          setContactForm({
            name: "",
            email: "",
            service: "",
            message: "",
          });
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        } else {
          setErrors(newErrors);
          setTimeout(() => {
            setErrors({});
          }, 3000);
        }
      };

  return (
    <div>
      <div className="contact-component">
      <div className="contact-content-container">
          <div className="contact-content">
            <div className="contact-header">
              <h2>Get in Touch</h2>
            </div>
            <div>
         
              <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <br />
                <input
                  type="text"
                  name="name"
                  value={contactForm.name}
                  placeholder="Enter a name"
                  onChange={handleChange}
                  className="contact2-input"
                />
                {errors.name && (
                  <span className="error-msg">{errors.name}</span>
                )}
                <br />
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="text"
                  name="email"
                  value={contactForm.email}
                  placeholder="Enter your email"
                  onChange={handleChange}
                  className="contact2-input"
                />
                {errors.email && (
                  <span className="error-msg">{errors.email}</span>
                )}
                <br />
                <br />
                <select onChange={handleChange} className="services-dropdown">
                  <option value="">Select a Service</option>
                  <option value="academic-writing">Academic Writing</option>
                  <option value="editing">Copywriting and Content writing</option>
                  <option value="proofreading">Proofreading</option>
                  <option value="translation">Social media management</option>
                  <option value="transcription">Advert and promotions</option>
                  <option value="copywriting">Branding and Design</option>
                  <option value="content-creation">Voice Over</option>
                </select>
                <br />
                <br />
                <label htmlFor="message">Message</label>
                <br />
                <textarea
                  type="text"
                  name="message"
                  value={contactForm.message}
                  onChange={handleChange}
                  className="contact2-input"
                />
                {errors.message && (
                  <span className="error-msg">{errors.message}</span>
                )}
                <br />
                <button type="submit" className="button contact2-btn">
                  Send
                </button>
                {successMessage && (
                  <span className="success-msg">{successMessage}</span>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
