import React from "react";
import './Info.css'

export default function Info(props) {
  return (
    <div>
      <div className="info--container">
        <h2 className="intro--header">{props.header}</h2>
        <div className="info--content">
          <div className="info-content-left">
            <p>{props.text1}</p>
            <p>{props.text2}</p>
          </div>
          <div className="info-content-right">
            <img src={props.image} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
