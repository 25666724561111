import React from "react";
import Banner from "../banner/Banner";
import Info from "../info/Info";
import Contact from "../contact/Contact";
import Footer from "../../pages/Footer";
import banner from "../../../src/branding-banner.png";
import proofInfo from "../../../src/brand-info.png";

export default function Branding() {
  return (
    <div>
      <div className="proofReading-container">
        <div className="proofReading-content">
          <Banner image={banner} />
          <Info
            header="Branding & Design"
            text1="A strong brand identity is essential for standing out in today’s competitive market."
            text2="Go Scholar offers comprehensive branding and graphic design services that help you create a distinctive and memorable brand. Our team develops unique logos, marketing materials, and brand strategies that reflect your business's personality and appeal to your target market. We ensure your brand communicates your values and connects with your audience on a deeper level.

Enhance your brand with our design services
"
            image={proofInfo}
          />
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  );
}
