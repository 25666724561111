import React, { useEffect, useState } from "react";
import AboutImage from "../About-bg.png";
import serviceImage4 from "../about-img2.png";
import serviceImage2 from "../mission.png";
import Team1 from "../team-1.png";
import Team2 from "../team-2.png";
import Team3 from "../team-3.png";
import Footer from "./Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import Faq from "../FaqData";

export default function About() {
  useEffect(() => {
    Aos.init();
  }, []);

  /* FAQ */
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  const [openIndex, setOpenIndex] = useState(null);
  const showIndex = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="about--page all--pages">
      <div className="bg-about">
        <img className="image2" src={AboutImage} alt="About us" />
        {/* <h1>Who We Are</h1> */}
      </div>
      <div className="about--content">
        <div className="about--content1">
          <div
            className="about-content-text"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h1>About Us</h1>
            <p>
              We are your one-stop solution for all your professional writing,
              branding, and promotional needs. We are dedicated to helping you
              achieve your goals through expert services tailored to meet the
              unique demands of academia, business, and beyond.
            </p>
            {/* <Link className="mail" to="mailto: worukaonard@gmail.com">
              <button className="services--btn">SEND US A MAIL</button>
            </Link> */}
          </div>
          <div
            className="about-img"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <img src={serviceImage4} />
          </div>
        </div>
        <br />

        {/* <div className="about--content1 vision">
          <div className="about-content-text" data-aos="zoom-in" data-aos-duration="1000">
            <h1>Our Vision</h1>
            <p>
              To be an excellent provider of access to world-class education
              opportunities to all, and to be an inspiration to our partner
              institutions and our students to take action to change our local
              and global communities.
            </p>
          </div>
          <div className="about-img" data-aos="zoom-in" data-aos-duration="1500">
            <img src={serviceImage7} />
          </div>
        </div> */}

        <br />
        <div className="about--content1 mission">
          <div
            className="about-content-text"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h1>Our Mission</h1>
            <p>
              Our mission is to empower individuals and businesses by providing
              high-quality, customized services that drive success and foster
              growth. We believe in the power of words, creativity, and
              strategic thinking to transform ideas into impactful outcomes.
            </p>
          </div>
          <div
            className="about-img"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <img src={serviceImage2} />
          </div>
        </div>
      </div>

      <section className="rating--content">
        <div className="rating">
          <div className="business">
            <h4>50+</h4>
            <p>Businesses</p>
          </div>
          <div className="education">
            <h4>10+</h4>
            <p>Educational Writings</p>
          </div>
          <div className="experience">
            <h4>2+</h4>
            <p>Years of Experience</p>
          </div>
        </div>
      </section>

      <section className="faq-container">
        <div className="faq--section">
          <h2 className="intro--header">FAQ</h2>
          <p>Frequently Asked Questions</p>
          <div className="faq--content">
            {Faq.map((faq, index) => (
              <div className="faq" key={index}>
                <div className="faq-question" onClick={() => showIndex(index)}>
                  <p>{faq.question}</p>
                  {openIndex === index ? <div className="faq--icon"><FaChevronDown  /></div>  : <div className="faq--icon"><FaChevronRight /></div>}
                </div>
                {openIndex === index && (
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="teams">
        <div className="teams-container">
          <h2 className="intro--header">Meet Our Team</h2>
          <div className="teams-content">
            <div className="team-left">
              <img src={Team1} alt="" className="team-img" />
              <div className="team-text">
                <p className="team-name">Bethel Okoye</p>
                <p className="team-position">Lead Creative Director</p>
              </div>
            </div>
            <div className="team-right">
              <div className="team-2">
                <img src={Team2} alt="" className="team-img" />
                <div className="team-text">
                  <p className="team-name">Steven Scott</p>
                  <p className="team-position">Design and Promotion Director</p>
                </div>
              </div>
              <div className="team-3">
                <img src={Team3} alt="" className="team-img" />
                <div className="team-text">
                  <p className="team-name">Jeremy Wills</p>
                  <p className="team-position">Business & Growth Director</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
