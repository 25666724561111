import React, { useState } from "react";
import modalImage from "../modal--gif.svg";
import { FaTimes } from "react-icons/fa";

export default function Modal() {
  const [modalVisible, setModalVisible] = useState(false);

  function closeModal() {
    setModalVisible(true);
  }
  return (
    <>
      <div className="modal">
        {modalVisible && (
          <div className="modal--content">
            <div className="modal-icon" onClick={closeModal}>
              <FaTimes />
            </div>
            <div className="modal-text">
              <p>We are in your email, please check your inbox.</p>
              <img src={modalImage} alt="gif" className="modal-gif" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
