import React, { useEffect } from "react";
import "./Academic.css";
import Footer from "../../pages/Footer";
import Banner from "../banner/Banner";
import academic from "../../../src/academic-banner.png";
import avatar from "../../../src/avatar.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Academic() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <Banner image={academic} />
      <div className="academic-container">
        <div className="academic-content">
          <h2 className="intro--header">
            Welcome to Our Academic Writing Services
          </h2>
          <p>
            At Go Scholar, we specialize in providing on-demand, tech-enabled
            educational support for students of all levels, from undergraduate
            to postgraduate. We connect students with a network of experienced
            and qualified online tutors and researchers, leveraging technology
            to deliver a seamless and personalized learning experience. Our
            mission is to help students achieve academic success by offering
            personalized academic guidance, research assistance, and coursework
            support.
          </p>
          <div className="academic-info">
            <br />
            <p className="intro--header"> Here’s how we can help you excel</p>
            <br />
            <div className="academic-grid">
              <div className="academic-left">
                <div className="academic-left-text"  data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Academic CVs</h5>
                  <p>
                    Present yourself effectively to academic institutions and
                    employers with a professionally crafted academic CV. We help
                    you highlight your achievements, skills, and experiences,
                    ensuring your CV stands out from the competition.
                  </p>
                </div>
                <div className="academic-left-img"  data-aos="fade-up"
                data-aos-duration="1000">
                  <img src={avatar} alt="" />
                </div>
                <div className="academic-left-text"  data-aos="fade-left"
                data-aos-duration="1500" >
                  <h5>Statement of Purpose</h5>
                  <p>
                    Your Statement of Purpose (SOP) is a critical part of your
                    application process. We assist in writing impactful SOPs
                    that reflect your academic goals, motivations, and the
                    unique contributions you can bring to your chosen program.
                  </p>
                </div>
              </div>
              <div className="academic-right">
                <div className="academic-right-text"  data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Research Assistance and Writing</h5>
                  <p>
                    Need help with your research projects? Our team of skilled
                    researchers offers comprehensive research assistance and
                    writing services. From data collection and analysis to
                    drafting and editing research papers, we ensure your
                    academic work meets the highest standards.
                  </p>
                </div>
                <div className="academic-right-text"  data-aos="fade-right"
                data-aos-duration="1500">
                  <h5>Motivation Letters</h5>
                  <p>
                    A well-written motivation letter can significantly enhance
                    your application. Our team helps you articulate your
                    passion, drive, and qualifications, making a strong case for
                    why you should be selected for your desired opportunity.
                  </p>
                </div>
                <div className="academic-right-text"  data-aos="fade-left"
                data-aos-duration="1500">
                  <h5>Research Proposals</h5>
                  <p>
                    Crafting a compelling research proposal is crucial for
                    securing academic opportunities. Our experts assist in
                    developing clear, concise, and persuasive research proposals
                    that effectively communicate your research goals and
                    methodology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="academic-bottom">
          <p>
            Experience the Difference with Go Scholar Academic writing Ready to
            take your academic performance to the next level?{" "}
          </p>
          <Link to="/getstarted" className="academic-btn">
            <button>Get Started</button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}
