import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Header from "./pages/Header";
import About from "./pages/About";
import Blog from "./pages/Blog";
import SingleBlogPage from "./pages/SingleBlogPage";
import ServicesDetails from "./pages/ServicesDetails";
import Contact from "./pages/Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import FormPage from "./pages/FormPage";
import SocialMedia from "./components/socialMedia/SocialMedia";
import ProofReading from "./components/proofreading/ProofReading";
import Branding from "./components/branding/Branding";
import Advert from "./components/advert/Advert";
import Voice from "./components/voice/Voice";
import Partner from "./pages/partner/Partner";
import Academic from "./components/academic/Academic";
import Copywrite from "./components/copyright/Copywrite";
import FormComponent from "./components/form/Form";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services/:id" element={<ServicesDetails />} />
          <Route path="/blog/:slug" element={<SingleBlogPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/social-media" element={<SocialMedia />} />
          <Route path="/proofreading" element={<ProofReading />} />
          <Route path="/Branding&Design" element={<Branding />} />
          <Route path="/advert&promotions" element={<Advert />} />
          <Route path="/voice-over" element={<Voice />} />
          <Route path="/academic-writing" element={<Academic />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/copywriting" element={<Copywrite />} />
          <Route path="/business-form" element={<FormComponent />} />
          <Route
            path="/academic-form"
            element={
              <div className="formPage">
                <FormPage />
              </div>
            }
          />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
