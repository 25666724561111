import React, { useState } from "react";
import contactImage from "../goscholar-contact.jpg";
import { FaPhoneAlt, FaEnvelope, FaSearchLocation } from "react-icons/fa";
import Footer from "./Footer";
import { Link } from "react-router-dom";

export default function Contact() {
  const [contactForm, setContactForm] = useState({
    name: "",
    phone: "",
    email: "",
    service: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!contactForm.name) newErrors.name = "Name is required";
    if (!contactForm.email) newErrors.email = "Email is required";
    if (!contactForm.message) newErrors.message = "Message is required";
    if (!contactForm.service) newErrors.service = "Please select a service";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      localStorage.setItem("Form data:", JSON.stringify(contactForm));
      setSuccessMessage("Message sent successfully!");
      setContactForm({
        name: "",
        email: "",
        service: "",
        message: "",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } else {
      setErrors(newErrors);
      setTimeout(() => {
        setErrors({});
      }, 3000);
    }
  };
  return (
    <div className="contact all--pages">
      <div className="contact-details">
        <div className="contact-bg">
          <img className="image2" src={contactImage} alt="About us" />
          <div className="image-overlay">
            <div className="image-overlay-content">
              <h1>Contact Us</h1>
              <p>
                Ready to elevate your success? Contact us today to learn how
                GOSCHOLAR can help you achieve your academic and business
                objectives. Let us turn your ideas into reality
              </p>
            </div>
          </div>
        </div>
        <div className="contact-content-container">
          <div className="contact-address">
            <div className="contact-phone-details">
              <div className="phone-content">
                <FaPhoneAlt className="contact-icon" />
                <div className="phone-text">
                  <p>24*7 Service</p>
                  <h5>Call us on</h5>
                  <p>Tel Number</p>
                  <p>+234 701 678 9077</p>
                </div>
              </div>
            </div>

            <div className="contact-phone-details">
              <div className="phone-content">
                <FaEnvelope className="contact-icon" />
                <div className="phone-text">
                  <p>Drop Us A Message</p>
                  <h5>Mail Address</h5>
                  <p>Hello@goscholar.com.ng</p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-content">
            <div className="contact-header">
              <h2>Get in Touch</h2>
            </div>
            <div>
         
              <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <br />
                <input
                  type="text"
                  name="name"
                  value={contactForm.name}
                  placeholder="Enter a name"
                  onChange={handleChange}
                  className="contact2-input"
                />
                {errors.name && (
                  <span className="error-msg">{errors.name}</span>
                )}
                <br />
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="text"
                  name="email"
                  value={contactForm.email}
                  placeholder="Enter your email"
                  onChange={handleChange}
                  className="contact2-input"
                />
                {errors.email && (
                  <span className="error-msg">{errors.email}</span>
                )}
                <br />
                <br />
                <select onChange={handleChange} className="services-dropdown">
                <option value="">Select a Service</option>
                  <option value="academic-writing">Academic Writing</option>
                  <option value="editing">Copywriting and Content writing</option>
                  <option value="proofreading">Proofreading</option>
                  <option value="translation">Social media management</option>
                  <option value="transcription">Advert and promotions</option>
                  <option value="copywriting">Branding and Design</option>
                  <option value="content-creation">Voice Over</option>
                </select>
                <br />
                <br />
                <label htmlFor="message">Message</label>
                <br />
                <textarea
                  type="text"
                  name="message"
                  value={contactForm.message}
                  onChange={handleChange}
                  className="contact2-input"
                />
                {errors.message && (
                  <span className="error-msg">{errors.message}</span>
                )}
                <br />
                <button type="submit" className="button contact2-btn">
                  Send
                </button>
                {successMessage && (
                  <span className="success-msg">{successMessage}</span>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
