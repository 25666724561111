import React from 'react'
import './Banner.css'

export default function Banner(props) {
  return (
    <div className='all--pages banner-container'>
      <img src={props.image} alt="" />
      <div className="banner-text">
        <p className='text1'>{props.text1}</p>
        <p className='text2'>{props.text2}</p>
      </div>
    </div>
  )
}
