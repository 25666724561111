import React from "react";
import Banner from "../banner/Banner";
import Info from "../info/Info";
import Contact from "../contact/Contact";
import Footer from "../../pages/Footer";
import banner from "../../../src/proof-banner.png";
import proofInfo from "../../../src/proof-info.png";

export default function ProofReading() {
  return (
    <div>
      <div className="proofReading-container">
        <div className="proofReading-content">
          <Banner image={banner} />
          <Info
            header="Proofreading"
            text1="At Go Scholar, we understand the importance of error-free and polished written content."
            text2=" Our expert proofreaders meticulously review your documents to ensure they are free from grammatical, punctuation, and typographical errors. Whether it's an academic paper, business report, or any other type of document, we ensure your work is refined for clarity and impact. Our team is dedicated to maintaining the integrity of your writing while enhancing its quality.

Contact us today for flawless proofreading services"
            image={proofInfo}
          />
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  );
}
